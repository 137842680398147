import { Hydratable } from '../interfaces/hydratable.interface';

export class PaginationData implements Hydratable {
  total!: number;
  limit!: number;
  pages!: number;
  current!: number;
  pagingCounter!: number;
  hasPrevPage!: boolean;
  hasNextPage!: boolean;
  prevPage!: number;
  nextPage!: number;
  
  hydrateWith(data: any): this {
    return Object.assign(this, data);
  }
}
