import { DataItemOperatorType } from './data-item-operator-type';
import { Hydratable } from './../interfaces/hydratable.interface';

export class DataItem implements Hydratable{
  id!: number;
  name!: string;
  description!: string;
  orderIndex!: number;
  required!: boolean;
  restrictionOperator!: string;
  restrictionValues!: any;
  type!: DataItemOperatorType;

  assigned!: boolean;

  constructor() {
    this.assigned = false;
    this.required = true;
  }

  static csvDelimiters = [';', ','];

  hydrateWith(data: any): this {
    this.restrictionValues = null;

    Object.assign(this, data);

    this.updateType();

    return this;
  }

  get isNew() {
    return !this.id && !this.assigned;
  }

  updateType() {
    this.type = new DataItemOperatorType(this.restrictionOperator, this.restrictionValues);
  }
}
