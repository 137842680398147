import { DataItem } from './data-item';
import { Hydratable } from './../interfaces/hydratable.interface';

export class DataStructure implements Hydratable{
  id!: number;
  name!: string;
  description!: string;
  pattern!: string;
  csvDelimiter!: string;
  dataItems!: DataItem[];
  fileCount!: number;

  constructor() {
    this.dataItems = [];
  }

  hydrateWith(data: any): this {
    Object.assign(this, data);

    if (data.dataItems) {
      this.dataItems = data.dataItems.map((dataItemData: any) => new DataItem().hydrateWith(dataItemData));
    }

    return this;
  }

  get isNew() {
    return !this.id;
  }

  get isEditable() {
    return !this.fileCount;
  }
}
