import { Client } from './../models/client';
import { DashboardDataCollection } from './../models/dashboard-data-collection';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable()
export class DashboardService {

  constructor(
    private http: HttpClient
  ) { }

  getDatas(client: Client | null = null) {
    const queryStringParams = [];

    if (client) {
      queryStringParams.push('clientId=' + client._id);
    }

    const queryString = queryStringParams.join('&');

    return this.http.get(`${environment.apiUrl}/dashboard/all?${queryString}`).pipe(
      map((resp: any) => new DashboardDataCollection().hydrateWith(resp.data))
    );
  }
}
