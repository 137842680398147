import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ActivateComponent } from './activate/activate.component';


@NgModule({
  declarations: [
    LoginComponent,
    NotFoundComponent,
    ActivateComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ]
})
export class GuestModule { }
