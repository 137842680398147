import { Operation } from './operation';
import { Icon } from './icon';
import { DataItem } from './data-item';
import { Hydratable } from './../interfaces/hydratable.interface';

export class BusinessDataType implements Hydratable{
  id!: number;
  icon!: Icon;
  direction!: number;
  operation!: Operation;
  name!: string;
  description!: string;
  isDisabled!: boolean;

  constructor() {
    this.direction = 0;
  }

  hydrateWith(data: any): this {
    Object.assign(this, data);

    if (data.icon) {
      this.icon = new Icon().hydrateWith(data.icon);
    }
    if (data.operation) {
      this.operation = new Operation().hydrateWith(data.operation);
    }
    this.direction = Number(data.direction);

    return this;
  }

  get isNew() {
    return !this.id;
  }
}
