// import { ActivateComponent } from './guest/activate/activate.component';
// import { AuthGuard } from './guards/auth.guard';
// import { AuthNotGuard } from './guards/auth-not.guard';
import { NotFoundComponent } from './guest/not-found/not-found.component';
// import { LoginComponent } from './guest/login/login.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // {
  //   path: 'login',
  //   component: LoginComponent,
  //   canActivate: [AuthNotGuard]
  // },
  // {
  //   path: 'activate/:userId/:token',
  //   component: ActivateComponent,
  //   canActivate: [AuthNotGuard]
  // },
  {
    path: 'ds',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    // canActivate: [AuthGuard],
    // canActivateChild: [AuthGuard]
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'ds/admin/applications'
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
