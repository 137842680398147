import { DashboardData } from './dashboard-data';
import { Hydratable } from './../interfaces/hydratable.interface';

export class DashboardBoxData implements Hydratable{

  dashboardData!: DashboardData;
  data!: {
    fileCount: number
  };

  hydrateWith(data: any): this {
    Object.assign(this, data);

    this.dashboardData = new DashboardData().hydrateWith(data.dashboardData);

    return this;
  }
}
