export class DataItemOperatorType {
  operator!: string;
  name!: string;
  restrictions!: any;
  availableRestrictions!: any[];

  static types = [
    {
      operator: 'number',
      name: 'Número',
      restrictions: [
        {
          restriction: 'min',
          name: 'Largo mín',
          inputType: 'number',
          required: false
        },
        {
          restriction: 'max',
          name: 'Largo máx',
          inputType: 'number',
          required: false
        }
      ]
    },
    {
      operator: 'float',
      name: 'Número decimal',
      restrictions: []
    },
    {
      operator: 'datetime',
      name: 'Fecha',
      restrictions: [
        {
          restriction: 'format',
          name: 'Formato',
          inputType: 'select',
          required: true,
          options: [
            {
              display: 'd/m/Y (09/12/2021)',
              value: 'd/m/Y'
            },
            {
              display: 'd-m-Y (09-12-2021)',
              value: 'd-m-Y'
            },
            {
              display: 'Y-m-d (2021-02-15)',
              value: 'Y-m-d'
            },
            {
              display: 'Y-m-d H:i (2021-02-15 14:05)',
              value: 'Y-m-d H:i'
            },
            {
              display: 'Y-m-d H:i:s (2021-02-15 14:05:01)',
              value: 'Y-m-d H:i:s'
            },
          ]
        }
      ]
    },
    {
      operator: 'string',
      name: 'Texto',
      restrictions: [
        {
          restriction: 'min',
          name: 'Largo mín',
          inputType: 'number',
          required: false
        },
        {
          restriction: 'max',
          name: 'Largo máx',
          inputType: 'number',
          required: false
        }
      ]
    },
    {
      operator: 'choice',
      name: 'Lista de valores',
      restrictions: [
        {
          restriction: 'choice',
          name: 'Opciones',
          inputType: 'text',
          required: true
        }
      ]
    }
  ];

  constructor(operator: string, restrictions: any) {
    for (let type of DataItemOperatorType.types) {
      if (type.operator === operator) {
        this.operator = type.operator;
        this.name = type.name;

        let restrictionsTmp = [];
        for (let restriction of type.restrictions) {
          if (typeof restrictions[restriction.restriction] === 'undefined') {
            continue;
          }

          let value = restrictions[restriction.restriction];

          let displayValue = Array.isArray(value) ? value.join(',') : value;

          restrictionsTmp.push({
            restriction: restriction.restriction,
            name: restriction.name,
            value,
            displayValue
          });
        }

        this.availableRestrictions = type.restrictions;
        this.restrictions = restrictionsTmp;
      }
    }
  }
}
