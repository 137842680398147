import { User } from './../models/user';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, timeoutWith } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { environment } from './../../environments/environment';

@Injectable()
export class AuthService {

  private accessToken!: string | null;
  private refreshToken!: string | null;
  private user!: User | null;

  constructor(
    private http: HttpClient
  ) {
    this.readStorageData();
  }

  login(user: User) {
    return this.http.post(`${environment.apiUrl}/auth/login`, user)
      .pipe(
        map((response: any) => {
          this.saveDataToStorage(response);
          this.readStorageData();

          return this.getUser();
        })
      );
  }

  refreshAccessToken() {
    if (!this.isLoggedIn()) {
        return throwError('El usuario no se encuentra logueado');
    }

    const json = {
        userId: this.getUser()?.id,
        refreshToken: this.getRefreshToken()
    };

    return this.http.post(`${environment.apiUrl}/auth/refresh`, json).pipe(
      map((response: any) => {
        this.saveAccessToken(response.data.accessToken);

        return response;
      })
    );
  }

  logout() {
    const json = {
      userId: this.getUser()?.id,
      refreshToken: this.getRefreshToken()
    };

    return this.http.post(`${environment.apiUrl}/auth/logout`, json).pipe(
      map((response: any) => {
        this.clearStorageData();

        return response;
      })
    );
  }

  activate(user: User, activationToken: string) {
    const data = {
      userId: user.id,
      activationToken,
      password: user.password
    };

    return this.http.post(`${environment.apiUrl}/auth/activate`, data)
      .pipe(
        map((response: any) => {
          this.saveDataToStorage(response);
          this.readStorageData();

          return this.getUser();
        })
      );
  }

  sendActivationMail(user: User) {
    const data = {
      userId: user.id
    };

    return this.http.post(`${environment.apiUrl}/auth/send-activation-mail`, data);
  }

  isLoggedIn(role?: string): boolean {
    if (!role) {
      return Boolean(this.getUser());
    }

    return Boolean(this.getUser()?.hasRole(role));
  }

  getUser(): User | null {
    return this.user;
  }

  getAccessToken(): string | null {
    return this.accessToken;
  }

  getRefreshToken(): string | null {
    return this.refreshToken;
  }

  private saveDataToStorage(response: any) {
    this.saveUser(response.data.user);
    this.saveAccessToken(response.data.accessToken);
    this.saveRefreshToken(response.data.refreshToken);
  }

  private saveAccessToken(token: string) {
    localStorage.setItem('access_token', token);
    this.readAccessToken();
  }

  private saveRefreshToken(token: string) {
    localStorage.setItem('refresh_token', token);
  }

  private saveUser(user: User) {
    localStorage.setItem('user_identity', JSON.stringify(user));
  }

  private readStorageData() {
    this.readUser();
    this.readAccessToken();
    this.readRefreshToken();
  }

  private readUser(): User | null {
    const userData = localStorage.getItem('user_identity');

    if (!userData) {
      return null;
    }

    this.user = new User().hydrateWith(JSON.parse(userData));

    return this.user;
  }

  private readAccessToken() {
    this.accessToken = localStorage.getItem('access_token');

    return this.accessToken;
  }

  private readRefreshToken() {
    this.refreshToken = localStorage.getItem('refresh_token');

    return this.refreshToken;
  }

  clearStorageData() {
    localStorage.removeItem('user_identity');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');

    this.user = null;
    this.accessToken = null;
    this.refreshToken = null;
  }
}
