import { Client } from './client';
import { BusinessDataType } from './business-data-type';
import { Hydratable } from './../interfaces/hydratable.interface';

export class DashboardData implements Hydratable{

  id!: number;
  client!: Client;
  businessDataType!: BusinessDataType;
  active!: boolean;
  displayBox!: boolean;
  displayGraph!: boolean;
  displayList!: boolean;

  constructor() {
    this.active = true;
    this.displayBox = false;
    this.displayGraph = false;
    this.displayList = false;
  }

  hydrateWith(data: any): this {
    Object.assign(this, data);

    if (data.client) {
      this.client = new Client().hydrateWith(data.client);
    }

    if (data.businessDataType) {
      this.businessDataType = new BusinessDataType().hydrateWith(data.businessDataType);
    }

    return this;
  }

  get isNew() {
    return !this.id;
  }
}
