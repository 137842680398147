import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Icon } from './../models/icon';

@Injectable()
export class IconService {

  constructor(
    private http: HttpClient
  ) { }

  create(icon: Icon) {
    return this.http.post(`${environment.apiUrl}/icon/`, icon).pipe(
      map((resp: any) => new Icon().hydrateWith(resp.data))
    );
  }

  retrieveAll() {
    return this.http.get(`${environment.apiUrl}/icon/`).pipe(
      map((resp: any) => resp.data.map((iconData: any) => new Icon().hydrateWith(iconData)))
    );
  }

  update(icon: Icon) {
    return this.http.put(`${environment.apiUrl}/icon/${icon.id}`, icon).pipe(
      map((resp: any) => new Icon().hydrateWith(resp.data))
    );
  }
}
