import { DataStructure } from './../models/data-structure';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable()
export class DataStructureService {

  constructor(
    private http: HttpClient
  ) { }

  create(dataStructure: DataStructure) {
    return this.http.post(`${environment.apiUrl}/data_structure/`, dataStructure).pipe(
      map((resp: any) => new DataStructure().hydrateWith(resp.data))
    );
  }

  retrieveAll() {
    return this.http.get(`${environment.apiUrl}/data_structure/`).pipe(
      map((resp: any) => resp.data.map((dataStructureData: any) => new DataStructure().hydrateWith(dataStructureData)))
    );
  }

  update(dataStructure: DataStructure) {
    return this.http.put(`${environment.apiUrl}/data_structure/${dataStructure.id}`, dataStructure).pipe(
      map((resp: any) => new DataStructure().hydrateWith(resp.data))
    );
  }
}
