import { User } from './../../models/user';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  form!: FormGroup;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'login-page');

    this.form = new FormGroup({
      username: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required)
    });
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'login-page');
  }

  login() {
    if (this.form.invalid) {
      Swal.fire('Error', 'Verifique el formulario', 'error');
      return;
    }

    Swal.fire({
      icon: 'info',
      title: 'Procesando',
      allowOutsideClick: false,
      allowEscapeKey: false
    });

    Swal.showLoading();

    const user = new User().hydrateWith({
      username: this.form.value.username,
      password: this.form.value.password
    });

    this.auth.login(user).subscribe(
      (loggedInUser: User | null) => {
        let returnUrl = this.route.snapshot.queryParams.returnUrl;

        if ((!returnUrl || returnUrl === '/ds') && loggedInUser) {
          returnUrl = loggedInUser.isAdmin ? '/ds/admin' : '/ds/client';
        }

        this.router.navigateByUrl(returnUrl);
        Swal.close();
      },
      errorResp => Swal.fire('Error al autenticar', errorResp.error, 'error')
    );
  }
}
