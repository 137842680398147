import { CsvValidationResponse } from './../models/csv-validation-response';
import { DataStructure } from './../models/data-structure';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import { File as AppFile } from './../models/file';
import { PaginationData } from '../models/pagination-data';
import { Directory } from './../models/directory';

@Injectable()
export class FileService {

  constructor(
    private http: HttpClient
  ) {}

  validate(dataStructure: DataStructure, file: File) {
    const formData = new FormData();

    formData.append('dataStructureId', dataStructure.id.toString());
    formData.append('csv', file);

    return this.http.post(`${environment.apiUrl}/file/validate`, formData).pipe(
      map((resp: any) => new CsvValidationResponse().hydrateWith(resp.data))
    );
  }

  create(directory: Directory, dataStructure: DataStructure, file: File) {
    const formData = new FormData();

    formData.append('directoryId', directory.id.toString());
    formData.append('dataStructureId', dataStructure.id.toString());
    formData.append('csv', file);

    return this.http.post(`${environment.apiUrl}/file/`, formData).pipe(
      map((resp: any) => new AppFile().hydrateWith(resp.data))
    );
  }

  retrieve(id: number) {
    return this.http.get(`${environment.apiUrl}/file/${id}`).pipe(
      map((resp: any) => new AppFile().hydrateWith(resp.data))
    );
  }

  download(id: number) {
    return this.http.get(`${environment.apiUrl}/file/${id}?download=1`, {observe: 'response', responseType: 'blob' as 'json'}).pipe(
      map((response: any) => {
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response.body);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        downloadLink.setAttribute('download', this.getFileName(response));
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.parentNode?.removeChild(downloadLink);
        return response;
      })
    );
  }

  retrieveAll(directoryId: number, dataStructureId: number | null, page: number) {
    const queryStringParams = [];

    queryStringParams.push('directoryId=' + directoryId);

    if (dataStructureId) {
      console.log(dataStructureId);
      queryStringParams.push('dataStructureId=' + dataStructureId);
    }
    if (page) {
      queryStringParams.push('page=' + page);
    }

    const queryString = queryStringParams.join('&');

    return this.http.get(`${environment.apiUrl}/file/?${queryString}`).pipe(
      map((resp: any) => ({
        files: resp.data.map((fileData: any) => new AppFile().hydrateWith(fileData)),
        paginationData: new PaginationData().hydrateWith(resp.paginationData)
      }))
    );
  }

  delete(file: AppFile) {
    return this.http.delete(`${environment.apiUrl}/file/${file.id}`);
  }

  getFileName(response: HttpResponse<Blob>) {
    let filename: string;
    try {
      const contentDisposition: string | null = response.headers.get('content-disposition');

      if (!contentDisposition) {
        throw new Error();
      }

      const r = /(?:filename=)(.+)(?:)/;
      const matches = r.exec(contentDisposition);

      if (!matches) {
        throw new Error();
      }

      filename = matches[1];
    } catch (e) {
      filename = 'myfile.txt'
    }
    return filename
  }
}
