import { Hydratable } from './../interfaces/hydratable.interface';

export class Client implements Hydratable {
  _id!: string;
  applicationId!: string;
  subscriptionId!: string;
  name!: string;
  lastname!: string;
  email!: string;
  createdAt!: string;
  updatedAt!: string;  

  constructor() {}

  hydrateWith(data: any): this {
    Object.assign(this, data);

    // Se comenta las sig. lineas por el sig. caso que come muchisimos recursos
    // Warning: Circular dependency detected:
    // src/app/models/user.ts -> src/app/models/client.ts -> src/app/models/user.ts
    // if (data.users) {
    //   this.users = data.users.map((userData: any) => new User().hydrateWith(userData, false));
    // }

    return this;
  }

  isNew = () => !this._id;
}
