import { User } from './../../models/user';
import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import Swal from 'sweetalert2';

import { AuthService } from './../../services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.css']
})
export class ActivateComponent implements OnInit, OnDestroy {

  form!: FormGroup;

  user!: User;
  activationToken!: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private authService: AuthService
  ) {
    this.user = new User();

    this.user.id = Number(this.route.snapshot.paramMap.get('userId'));
    this.activationToken = this.route.snapshot.paramMap.get('token') || '';
  }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'login-page');

    this.form = this.formBuilder.group({
      password: [null, Validators.required],
      confirmPassword: [null, Validators.required]
    });
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'login-page');
  }

  onSubmit() {
    if (this.form.invalid) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'El formulario contiene errores.'
      });
      return;
    }

    if (this.form.controls.password.value !== this.form.controls.confirmPassword.value) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Las contraseñas no coinciden.'
      });
      return;
    }

    Swal.fire({
      icon: 'info',
      title: 'Procesando',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();

    const user = new User().hydrateWith(this.user).hydrateWith(this.form.value);

    this.authService.activate(user, this.activationToken).subscribe(
      () => {
        Swal.close();
        this.router.navigate(['/ds/client']);
      },
      (error: any) => {
        Swal.fire('Error', error.error, 'error');
      }
    );
  }

}
