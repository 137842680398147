<div class="d-flex align-items-center justify-content-lg-center" style="height: 100vh;">
    <div class="error-page m-0 animate__animated animate__tada animate__faster">
        <h2 class="headline text-warning"> 404</h2>

        <div class="error-content">
            <h3><i class="fas fa-exclamation-triangle text-warning"></i> Oops! No se encontró...</h3>

            <p>
                La página que estaba buscando no se encontró..
            </p>

        </div>
        <!-- /.error-content -->
    </div>
</div>
