import { Hydratable } from './../interfaces/hydratable.interface';

export class CsvValidationResponse implements Hydratable {
  valid!: boolean;
  errors: {
    row: number,
    errors: {
      detail: string,
      violations: {
        propertyPath: string,
        column: number,
        title: string
      }[]
    }
  }[] = [];

  hydrateWith(data: any): this {
    Object.assign(this, data);

    console.log('lalala 1');

    for (let rowError of this.errors) {
      for (let violation of rowError.errors.violations) {
        violation.column = Number(violation.propertyPath.replace(/\[([0-9]+?)\]/, "$1")) + 1;
      }
    }
    console.log('lalala 2');

    return this;
  }
}
