<div class="login-box animate__animated animate__fadeIn animate__faster">
    <div class="login-logo"><img src="assets/gpp-logo.png"></div>
    <div class="card">
        <div class="card-body login-card-body">
            <form [formGroup]="form" (ngSubmit)="login()">
                <div class="form-group">
                    <label>Usuario</label>
                    <input class="form-control" formControlName="username" name="username" type="text">
                </div>
                <div class="form-group">
                    <label>Contraseña</label>
                    <input class="form-control" formControlName="password" name="password" type="password">
                </div>
                <br>
                <button type="submit" class="btn btn-login btn-block">Ingresar</button>
            </form>
        </div>
    </div>
</div>
