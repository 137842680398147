import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(

      catchError(errorResponse => {
        // Si el error es un error de http, Creamos un nuevo error con el mensaje que
        // recibimos de la API. Si no viene ningún mensaje, mostramos uno por defecto.
        if (errorResponse instanceof HttpErrorResponse) {
          return throwError(new HttpErrorResponse({
            error: typeof errorResponse.error.error !== undefined ? errorResponse.error.error : 'Ha ocurrido un error',
            headers: errorResponse.headers,
            status: errorResponse.status,
            statusText: errorResponse.statusText,
            url: errorResponse.url ?? undefined
          }));
        }

        return throwError(errorResponse);
      })
    );
  }
}
