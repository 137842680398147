import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Client } from './../models/client';
import { ApiResponse } from '../interfaces/api-response.interface';
import { ClientDTO } from '../dtos/client.dto';

@Injectable()
export class ClientService {

  constructor(
    private http: HttpClient
  ) { }
  retrieveAll = async (): Promise<Client[]> =>  {
    const { data } = await this.http.get<ApiResponse<ClientDTO[]>>(`${environment.apiUrl}/client`).toPromise();
    return data.map( (client: ClientDTO) => new Client().hydrateWith(client));
  }

  retrieve = async (id: string): Promise<Client> => {
    const { data } = await this.http.get<ApiResponse<ClientDTO>>(`${environment.apiUrl}/client/${id}`).toPromise();
    return new Client().hydrateWith(data);
  }

  create = async (client: Client) => await this.http.post<ApiResponse<ClientDTO>>(`${environment.apiUrl}/client`, client).toPromise();

  update = async (client: Client) => await this.http.patch<ApiResponse<ClientDTO>>(`${environment.apiUrl}/client/${client._id}`, client).toPromise();
  
  //TODO: Validar si se puede borrar
  delete = async (id: string) => await this.http.delete<ApiResponse<ClientDTO>>(`${environment.apiUrl}/client/${id}`).toPromise();
}