import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import { Directory } from '../models/directory';
import { Client } from './../models/client';

@Injectable()
export class DirectoryService {

  constructor(
    private http: HttpClient
  ) {}

  create(directory: Directory) {
    return this.http.post(`${environment.apiUrl}/directory/`, directory).pipe(
      map((resp: any) => new Directory().hydrateWith(resp.data))
    );
  }

  retrieve(id: number) {
    return this.http.get(`${environment.apiUrl}/directory/${id}`).pipe(
      map((resp: any) => new Directory().hydrateWith(resp.data))
    );
  }

  retrieveAll(parent_id?: number) {
    const queryStringParams = [];

    if (parent_id !== undefined) {
      queryStringParams.push('parent_id=' + parent_id);
    }

    const queryString = queryStringParams.join('&');

    return this.http.get(`${environment.apiUrl}/directory/?${queryString}`).pipe(
      map((resp: any) => ({
        directories: resp.data.map((directoryData: any) => new Directory().hydrateWith(directoryData))
      }))
    );
  }

  update(directory: Directory) {
    return this.http.put(`${environment.apiUrl}/directory/${directory.id}`, directory).pipe(
      map((resp: any) => new Directory().hydrateWith(resp.data))
    );
  }

  clientTree(clientId?: number) {

    console.log(clientId);

    const clientIdApendUrl: string = clientId ? `/${clientId.toString()}` : '';

    return this.http.get(`${environment.apiUrl}/directory/tree${clientIdApendUrl}`).pipe(
      map((resp: any) => ({
        directories: resp.data.map((directoryData: any) => new Directory().hydrateWith(directoryData))
      }))
    );
  }

  delete(directory: Directory) {
    return this.http.delete(`${environment.apiUrl}/directory/${directory.id}`);
  }
}
