import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { BusinessDataType } from './../models/business-data-type';

@Injectable()
export class BusinessDataTypeService {

  constructor(
    private http: HttpClient
  ) { }

  create(businessDataType: BusinessDataType) {
    return this.http.post(`${environment.apiUrl}/business_data_type/`, businessDataType).pipe(
      map((resp: any) => new BusinessDataType().hydrateWith(resp.data))
    );
  }

  retrieveAll() {
    return this.http.get(`${environment.apiUrl}/business_data_type/`).pipe(
      map((resp: any) => resp.data.map((businessDataTypeData: any) => new BusinessDataType().hydrateWith(businessDataTypeData)))
    );
  }

  update(businessDataType: BusinessDataType) {
    return this.http.put(`${environment.apiUrl}/business_data_type/${businessDataType.id}`, businessDataType).pipe(
      map((resp: any) => new BusinessDataType().hydrateWith(resp.data))
    );
  }
}
