import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Application } from '../models/application';
import { ApplicationDTO } from '../dtos/application.dto';

import { ApiResponse } from '../interfaces/api-response.interface';



@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {
  
  constructor(private http: HttpClient) { }
  
  retrieveAll = async (): Promise<Application[]> =>  {
    const { data } = await this.http.get<ApiResponse<ApplicationDTO[]>>(`${environment.apiUrl}/applications`).toPromise();
    return data.map( (app: ApplicationDTO) => new Application().hydrateWith(app));
  }
  
  //TODO: Meterle algo de validación a la API.
  retrieve = async (id: string): Promise<Application> => {
    const { data } = await this.http.get<ApiResponse<ApplicationDTO>>(`${environment.apiUrl}/applications/${id}`).toPromise();
    return new Application().hydrateWith(data);
  }

  create = async (application: Application) => await this.http.post<ApiResponse<ApplicationDTO>>(`${environment.apiUrl}/applications`, application).toPromise();

  update = async (application: Application) => await this.http.patch<ApiResponse<ApplicationDTO>>(`${environment.apiUrl}/applications/${application._id}`, application).toPromise();
  
  //TODO: Validar si se puede borrar
  delete = async (id: string) => await this.http.delete<ApiResponse<ApplicationDTO>>(`${environment.apiUrl}/applications/${id}`).toPromise();
}
