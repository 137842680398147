import { File as AppFile } from './file';
import { DashboardData } from './dashboard-data';
import { Hydratable } from './../interfaces/hydratable.interface';

export class DashboardListData implements Hydratable{

  dashboardData!: DashboardData;
  data!: AppFile[];

  hydrateWith(data: any): this {
    Object.assign(this, data);

    this.dashboardData = new DashboardData().hydrateWith(data.dashboardData);

    return this;
  }
}
