import { Directory } from './directory';
import { DataStructure } from './data-structure';
import { Hydratable } from './../interfaces/hydratable.interface';

export class File implements Hydratable{
  id!: number;
  fileName!: string;
  directory!: Directory | null;
  dataStructure!: DataStructure | null;
  content!: string[];
  csvDelimiter!: string;

  hydrateWith(data: any): this {
    Object.assign(this, data);

    this.directory = data.directory ? new Directory().hydrateWith(data.directory) : null;
    this.dataStructure = data.dataStructure ? new DataStructure().hydrateWith(data.dataStructure) : null;

    return this;
  }

  get csvDelimiterGuessed(): boolean {
    return !Boolean(this.dataStructure);
  }
}
