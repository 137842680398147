import { DashboardData } from './dashboard-data';
import { Hydratable } from './../interfaces/hydratable.interface';

export class DashboardGraphData implements Hydratable{

  dashboardData!: DashboardData;
  data!: {
    day: string;
    fileCount: number
  }[];

  hydrateWith(data: any): this {
    Object.assign(this, data);

    this.dashboardData = new DashboardData().hydrateWith(data.dashboardData);

    return this;
  }
}
