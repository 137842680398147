import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Client } from './../models/client';
import { User } from './../models/user';

@Injectable()
export class UserService {

  constructor(
    private http: HttpClient
  ) { }

  create(user: User) {
    return this.http.post(`${environment.apiUrl}/user/`, user).pipe(
      map((resp: any) => new User().hydrateWith(resp.data))
    );
  }

  retrieveAll() {
    return this.http.get(`${environment.apiUrl}/user/`).pipe(
      map((resp: any) => resp.data.map((userData: any) => new User().hydrateWith(userData)))
    );
  }

  update(user: User) {
    return this.http.put(`${environment.apiUrl}/user/${user.id}`, user).pipe(
      map((resp: any) => new User().hydrateWith(resp.data))
    );
  }
}
