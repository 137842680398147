import { Hydratable } from './../interfaces/hydratable.interface';

export class Operation implements Hydratable{
  id!: number;
  name!: string;
  description!: string;

  hydrateWith(data: any): this {
    Object.assign(this, data);

    return this;
  }
}
