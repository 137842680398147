import { Client } from './../models/client';
import { DashboardDataCollection } from './../models/dashboard-data-collection';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { DashboardData } from '../models/dashboard-data';

@Injectable()
export class DashboardDataService {

  constructor(
    private http: HttpClient
  ) { }

  create(dashboardData: DashboardData) {
    return this.http.post(`${environment.apiUrl}/dashboard/`, dashboardData).pipe(
      map((resp: any) => new DashboardData().hydrateWith(resp.data))
    );
  }

  retrieveAll(client: Client | null = null) {
    const queryStringParams = [];

    if (client) {
      queryStringParams.push('clientId=' + client._id);
    }

    const queryString = queryStringParams.join('&');

    return this.http.get(`${environment.apiUrl}/dashboard/?${queryString}`).pipe(
      map((resp: any) => resp.data.map((dashboardDataData: any) => new DashboardData().hydrateWith(dashboardDataData)))
    );
  }

  update(dashboardData: DashboardData) {
    return this.http.put(`${environment.apiUrl}/dashboard/${dashboardData.id}`, dashboardData).pipe(
      map((resp: any) => new DashboardData().hydrateWith(resp.data))
    );
  }
}
