import { Client } from './client';
import { BusinessDataType } from './business-data-type';
import { DataStructure } from './data-structure';
import { Hydratable } from './../interfaces/hydratable.interface';

export class Directory implements Hydratable{
  static readonly MODE_READ = '2';
  static readonly MODE_WRITE = '4';

  id!: number;
  name!: string;
  description!: string;
  path!: string;
  mode!: string;
  client!: Client | null;
  businessDataType!: BusinessDataType | null;
  dataStructures!: DataStructure[];
  children!: Directory[];
  parent!: Directory | null;
  parentId!: number | null;
  collapsed!: boolean;
  active!: boolean;

  constructor() {
    this.mode = Directory.MODE_READ;
    this.dataStructures = [];
  }

  hydrateWith(data: any): this {
    Object.assign(this, data);

    if (data.client) {
      this.client = new Client().hydrateWith(data.client);
    }
    if (data.businessDataType) {
      this.businessDataType = new BusinessDataType().hydrateWith(data.businessDataType);
    }
    this.dataStructures = data.dataStructures ? data.dataStructures.map((dataStructureData: any) => new DataStructure().hydrateWith(dataStructureData)) : null;
    this.children = data.children ? data.children.map((childData: any) => new Directory().hydrateWith(childData)) : null;

    this.collapsed = true;

    return this;
  }

  clone(data?: any): Directory {
    const directory = new Directory().hydrateWith(this);

    if (!data) {
      return directory;
    }

    return directory.hydrateWith(data);
  }

  get isNew() {
    return !this.id;
  }

  static getModes(): any[] {
    return [
      {
        name: 'Lectura',
        mode: Directory.MODE_READ
      },
      {
        name: 'Lectura/Escritura',
        mode: Directory.MODE_WRITE
      }
    ]
  }

  toggleCollapsed() {
    this.collapsed = !this.collapsed;
  }

  collapse(withChildren = false) {
    this.collapsed = true;

    if (!withChildren) {
      return;
    }

    this.children.forEach((directory: Directory) => directory.collapse(true));
  }

  unCollapse(withChildren = false) {
    if (!this.children.length) {
      return;
    }

    this.collapsed = false;

    if (!withChildren) {
      return;
    }

    this.children.forEach((directory: Directory) => directory.unCollapse(true));
  }

  get allowsRead() {
    return this.mode === Directory.MODE_READ || this.mode === Directory.MODE_WRITE;
  }

  get allowsWrite() {
    return this.mode === Directory.MODE_WRITE;
  }

  get allowsUploadFile() {
    return this.allowsWrite;
  }

  get allowsDeleteFile() {
    return this.allowsWrite;
  }
}
