import { Hydratable } from './../interfaces/hydratable.interface';

export class Application implements Hydratable{
  _id!: string;
  externalId!: string;
  name!: string;
  description!: string;
  slug!: string;
  createdAt!: string;
  updatedAt!: string;

  constructor() {}

  hydrateWith(data: any): this {
    Object.assign(this, data);

    return this;
  }

  isNew = (): boolean => !this._id;
}
