import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';

// Servicios
import { DirectoryService } from './directory.service';
import { FileService } from './file.service';
import { AuthService } from './auth.service';
import { DashboardService } from './dashboard.service';
import { ClientService } from './client.service';
import { UserService } from './user.service';
import { DataStructureService } from './data-structure.service';
import { BusinessDataTypeService } from './business-data-type.service';
import { IconService } from './icon.service';
import { OperationService } from './operation.service';
import { DashboardDataService } from './dashboard-data.service';
import { ApplicationsService } from './applications.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    ApplicationsService,
    DirectoryService,
    FileService,
    AuthService,
    DashboardService,
    ClientService,
    UserService,
    DataStructureService,
    BusinessDataTypeService,
    IconService,
    OperationService,
    DashboardDataService
  ]
})
export class ServicesModule { }
