import { Hydratable } from './../interfaces/hydratable.interface';

export class Icon implements Hydratable{
  id!: number;
  name!: string;
  value!: string;

  hydrateWith(data: any): this {
    Object.assign(this, data);

    return this;
  }

  get isNew() {
    return !this.id;
  }
}
