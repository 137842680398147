import { DashboardListData } from './dashboard-list-data';
import { DashboardGraphData } from './dashboard-graph-data';
import { DashboardData } from './dashboard-data';
import { Hydratable } from './../interfaces/hydratable.interface';
import { DashboardBoxData } from './dashboard-box-data';

export class DashboardDataCollection implements Hydratable{

  boxData!: DashboardBoxData[];
  graphData!: DashboardGraphData[];
  listData!: DashboardListData[];

  hydrateWith(data: any): this {
    Object.assign(this, data);

    this.boxData = data.boxData.map((boxDataData: any) => new DashboardBoxData().hydrateWith(boxDataData));
    this.graphData = data.graphData.map((graphDataData: any) => new DashboardGraphData().hydrateWith(graphDataData));
    this.listData = data.listData.map((listDataData: any) => new DashboardListData().hydrateWith(listDataData));

    return this;
  }

  get isEmpty() {
    return !this.boxData.length && !this.graphData.length && !this.listData.length;
  }
}
