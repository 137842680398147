import { Hydratable } from './../interfaces/hydratable.interface';
import { Client } from './client';

export class User implements Hydratable {
  id!: number;
  username!: string;
  name!: string;
  surname!: string;
  email!: string;
  password!: string;
  client!: Client | null;
  active!: boolean;
  activated!: boolean;
  roles!: string[];

  constructor() {
    this.active = true;
    this.activated = false;
  }

  get fullName(): string {
    return `${this.name} ${this.surname}`;
  }

  get isNew() {
    return !this.id;
  }

  hydrateWith(data: any, withClient: boolean = true): this {
    if (!data) {
      return this;
    }

    Object.assign(this, data);

    if (withClient && data.client) {
      this.client = new Client().hydrateWith(data.client);
    }

    return this;
  }

  hasRole(role: string) {
    return this.roles.includes(role);
  }

  get isClient() {
    return this.hasRole('ROLE_CLIENT');
  }

  get isAdmin() {
    return this.hasRole('ROLE_ADMIN');
  }
}
